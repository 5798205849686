import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import CognitoForm from "../components/cognitoFormSlice";
import SliceR from "../components/slice-r";
import { filterArrayByType } from '../utility';

const ArticlePage = ({ pageContext }) => {
  console.log("PAGE CONTEXT", pageContext);
  const article = pageContext.node.data;
  console.log(article);

  let articleDate = (article.article_date)
    ? new Date(article.article_date + "T00:00:00")
    : new Date(pageContext.node.first_publication_date);

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let article_date_string = articleDate.toLocaleDateString('en-US', {
    year: 'numeric', month: 'long', day: 'numeric'
  });

  let calloutBoxes = filterArrayByType(article.body, "callout_box");
  console.log("callout Boxes", calloutBoxes);
  console.log("article", article);
  let formSlice = filterArrayByType(article.body, "form_id");
  let formData = formSlice[0]?.primary || {};
  console.log(formData);

  let bodySlices = article.body.filter(slice =>
    slice.slice_type !== "form_id" && slice.slice_type !== "callout_box"
  );

  return (
    <Layout>
      <SEO
        title={article.meta_title}
        image={article.meta_og_image.url}
        noindex={article.meta_noindex}
        description={article.meta_description}
        canonical={
          typeof window !== "undefined" &&
          window.location.origin + window.location.pathname
        }
      />

      <div
        className="title-bar title-bar-xl banner-work-article-2"
        style={{ backgroundImage: `url(${article.wide_image.url})` }}
      >
        <div className="container">
          <a href="" className="page-scroll">
            <span className="icn-arrows-down">[Down Arrow]</span>
          </a>
          <div className="wrapper">
            <h2 className="page-title">
              {article.article_type ? article.article_type : "Article"}
            </h2>
            <div className="title-bar-accent"></div>
          </div>
        </div>
      </div>

      {/*  PAGE BODY  */}
      <section id="body-area">
        <div className="container">
          <div className="margin-bottom-0">
            {article_date_string && (
              <p className="font-base bold txt-gray-95 txt-14 txt-uppercase no-margin">{article_date_string}</p>
            )}
            {article.article_author && (
              <p className="font-base bold txt-gray-95 txt-14 txt-uppercase margin-bottom-20">By {article.article_author}</p>
            )}
            <h1 className="headline">{article.article_title.text}</h1>
            <div className="row">
              <div className="col-12">
                <div className="content-wrapper">
                  {(calloutBoxes.length || article.form_position) && (
                    <aside className="sidebar-content">
                      {calloutBoxes.map((calloutBox, j) => (
                        <div key={j} className="box style-1">
                          <h3 className="tag">{calloutBox.primary.callout_box_label.text || ""}</h3>
                          <div className="callout-text" dangerouslySetInnerHTML={{ __html: calloutBox.primary.callout_box_body_text.html || "" }}></div>
                          {calloutBox.primary?.callout_box_cta_link?.url && (
                            <a href={calloutBox.primary.callout_box_cta_link.url} className="btn btn-primary">
                              {calloutBox.primary.callout_box_cta_text} →
                            </a>
                          )}
                        </div>
                      ))}
                      {(article.form_position && formData?.form_id) && (
                        <CognitoForm id={formData.form_id} />
                      )}
                    </aside>
                  )}
                  <div className="article-body" dangerouslySetInnerHTML={{ __html: article.article_body_text.html }}></div>

                  {bodySlices.map((slice, index) => (
                    <SliceR key={index} data={slice} />
                  ))}

                  {(!article.form_position && formData?.form_id) && (
                    <CognitoForm id={formData.form_id} />
                  )}
                </div>
              </div>
            </div> {/*  /row  */}
          </div> {/*  /box  */}
        </div> {/*  /container  */}
      </section>
    </Layout>
  );
};

export default ArticlePage;
