import React from "react";
import MixedRichText from './mixedRichText';
import InformationGrid from './informationGrid';

const SliceR = ({ data, classList = '', ...args }) => {
  if (!data) return null;
  if (typeof data.slice_type === "undefined") return null;

  switch (data.slice_type.replace(/\d+$/, '')) {
    case "mixed_rich_text":
      return <MixedRichText data={data} {...args} />
    case "information_grid":
      return <InformationGrid data={data} {...args} />
  }
}

export default SliceR;