import React from 'react';
import { renderCognitoForm } from '../utility.js';

class CognitoForm extends React.Component {
  componentDidMount(){
    renderCognitoForm(String(this.props.id));
  }
  
  render() {
    return (
      <div className={`cognito cognitoForm`}></div>
    );
  }
}

export default CognitoForm;