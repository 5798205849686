import React from "react";

const MixedRichtext = ({ data }) => {

  let mixedRich = data.primary;

  return (
    <div dangerouslySetInnerHTML={{ __html: mixedRich?.body_content?.html }}></div>
  )
}

export default MixedRichtext;