import React from "react";

const InformationGrid = ({ data }) => {

  console.log(data);
  let gridItems = data.items;
  console.log(gridItems);
  console.log(gridItems[0].information_block.html);

  return (

    <section>
      <div className={`info-grid-wrapper ${(gridItems.length === 2 || gridItems.length === 4) ? 'twin' : 'triple'}`} style={{backgroundColor: data.primary.background_color}}>
        {gridItems.map((item, index) => {
          return (
            <div key={index} className="info-grid-item" dangerouslySetInnerHTML={{ __html: item?.information_block?.html }}></div>
          )
        })}
      </div>
    </section>
  )
}

export default InformationGrid;